<template>
  <div class="dt-block">
  
    <div class="dt-header">
      <div class="dt-header-main">
        <el-button
          icon="el-icon-back"
          circle
          size="small"
          @click="$router.go(-1)"
        >
        </el-button>
        <span style="margin-left: 20px;">{{ form.name }}</span>
      </div>
      <div>
        <el-button
          icon="el-icon-refresh"
          circle
          @click="refresh"
        >
        </el-button>
      </div>
    </div>
  
    <div class="dt-data">

      <div class="dt-form">
        
        <el-form
          ref="form"
          :model="form"
          label-width="180px"
          v-loading="loading"
        >
          
          <el-form-item label="ID">
            <el-input v-model="form.id" disabled style="width: 140px;"></el-input>
          </el-form-item>

          <el-form-item label="Имя">
            <el-input v-model="form.name" style="max-width: 400px;"></el-input>
          </el-form-item>

          <el-form-item label="Имя">
            <el-input v-model="form.email" style="max-width: 400px;"></el-input>
          </el-form-item>

          <el-form-item label="Пароль">
            <el-input v-model="form.password" style="max-width: 400px;">
              <i
                class="el-icon-refresh el-input__icon"
                slot="suffix"
                @click="generatePassword">
              </i>
            </el-input>
          </el-form-item>

          <el-form-item label="Телефон">
            <el-input v-model="form.phone" style="max-width: 400px;"></el-input>
          </el-form-item>

          <el-form-item label="Должность">
            <el-input v-model="form.position" style="max-width: 400px;"></el-input>
          </el-form-item>

          <el-form-item
            label="Роль"
          >
            <el-select
              v-model="form.role_id"
            >
              <el-option
                v-for="m in options.roles"
                :key="m.value"
                :label="m.label"
                :value="m.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          
          <el-form-item
            label="Руководитель"
            filterable
          >
            <el-select
              v-model="form.chief_id"
            >
              <el-option
                v-for="m in options.chiefs"
                :key="m.value"
                :label="m.label"
                :value="m.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          
          <el-form-item>
            <el-button type="primary" @click="updateUser">Изменить</el-button>
          </el-form-item>

        </el-form>

      </div>

    </div>
  
  </div>
</template>

<script>
import { Notification } from 'element-ui';

export default {

  name: 'ManagersView',
  
  props: {
    user_id: String,
  },
  
  data() {
    return {
      form: {
        id: 0,
        name: '',
        email: '',
        password: '',
        phone: '',
        position: '',
        role_id: 0,
        chief_id: 0,
      },
      options: {
        chiefs: [ { label: 'Не назначен', value: 0 } ],
        roles: [],
      },
      loading: false,
    }
  },
  
  mounted() {
    this.get_user();
  },

  methods: {

    /* refresh */
    refresh() {
      this.get_user();
    },

    /* get_user */
    get_user() {
      this.loading = true;
      this.$request({
        path: 'new/user/' + this.user_id.toString(),
        data: {},
        call: [ this.get_user_complete, this ],
        cache: false,
      });
    },

    /* get_user_complete */
    get_user_complete(status, data) {
      if(status == 200) {
        this.options.chiefs.splice(1, this.options.chiefs.length - 1);
        Array.prototype.push.apply(this.options.chiefs, data.chiefs.filter(u => u.value != data.user.id));
        this.options.roles.splice(0, this.options.roles.length);
        Array.prototype.push.apply(this.options.roles, data.roles);
        this.form.id = data.user.id;
        this.form.name = data.user.name;
        this.form.email = data.user.email;
        this.form.password = data.user._password;
        this.form.phone = data.user.phone;
        this.form.position = data.user.position;
        this.form.role_id = data.user.roles_ids[0];
        this.form.chief_id = data.user.chief_id;
      }
      this.loading = false;

    },

    /* updateUser */
    updateUser(req) {
      this.loading = true;
      const data = {
        user_id: this.form.id,
        name: this.form.name,
        email: this.form.email,
        password: this.form.password,
        phone: this.form.phone,
        position: this.form.position,
        role_id: this.form.role_id,
        chief_id: this.form.chief_id,
      };
      this.$request({
        path: '/new/user/update',
        data: data,
        call: [ this.updateUser_complete, this ],
        cache: false,
      });
    },

    /* updateUser_complete */
    updateUser_complete(status, data) {
      if(status != 200) {
        if(data._alert) {
          Notification({
            title: data._alert,
            message: '',
            customClass: 'd-update-error',
            //duration: 0,
          });
        }
        this.loading = false;
      }
      else {
        this.refresh();
      }
    },

    /* generatePassword */
    generatePassword() {
      var password = '';
      const characters = '0123456789';
      const charactersLength = characters.length;
      for(var i = 0; i < 9; i++)
        password += characters.charAt(Math.floor(Math.random() * charactersLength));
      this.form.password = password;
    },

  },
  
};
    

function parseDatetime(s, fromUTC) {
  var d = null;
  const sp = s.split(/[- :]/);
  if(fromUTC) {
    d = new Date(Date.UTC(sp[0], sp[1] - 1, sp[2], sp[3] ? sp[3] : 0, sp[4] ? sp[4] : 0, sp[5] ? sp[5] : 0));
  }
  else {
    d = new Date(sp[0], sp[1] - 1, sp[2], sp[3] ? sp[3] : 0, sp[4] ? sp[4] : 0, sp[5] ? sp[5] : 0);
  }
  return d;
}

function formatDatetime(v, f, fromUTC, toUTC) {
  const fm = typeof f !== 'string' ? 'YYYY-MM-DD hh:mm:ss' : f;
  var d = typeof v === 'string' ? d = parseDatetime(v, fromUTC) : v;
  const mask = toUTC ?
    {
      'YYYY': d.getUTCFullYear(),
      'MM': ('0' + (d.getUTCMonth() + 1).toString()).slice(-2),
      'DD': ('0' + d.getUTCDate()).slice(-2),
      'hh': ('0' + d.getUTCHours()).slice(-2),
      'mm': ('0' + d.getUTCMinutes()).slice(-2),
      'ss': ('0' + d.getUTCSeconds()).slice(-2),
    } :
    {
      'YYYY': d.getFullYear(),
      'MM': ('0' + (d.getMonth() + 1).toString()).slice(-2),
      'DD': ('0' + d.getDate()).slice(-2),
      'hh': ('0' + d.getHours()).slice(-2),
      'mm': ('0' + d.getMinutes()).slice(-2),
      'ss': ('0' + d.getSeconds()).slice(-2),
    };
  const str = fm.replace(/(Y+|M+|D+|h+|m+|s+)/g, (s, g) => mask[g]);
  return str;
}
</script>

<style scope>
.el-notification.d-update-error {
  background-color: #F56C6C;
  border: 0;
}

.d-update-error .el-notification__group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.d-update-error .el-notification__title {
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  margin: 0;
}

.d-update-error .el-notification__closeBtn {
  position: static;
  margin-top: 2px;
  color: rgba(255, 255, 255, .6);
}

.dt-header {
  background-color: rgb(246, 248, 248);
  border-bottom: 1px solid rgb(222, 229, 231);
  padding: 20px;
  height: 80px;
  line-height: 40px;
  font-size: 22px;
  font-weight: 300;
  color: rgb(88, 102, 110);
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dt-data {
  padding: 0px 0px 20px 0px;
  margin-bottom: 20px;
}

.dt-p {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.dt-form {
  padding: 20px 20px 0px 20px;
  margin-bottom: 2px;
  flex: 1 1 70%;
}

.dt-info {
  padding: 20px 20px 0px 20px;
  margin-bottom: 22px;
  flex: 0 0 320px;
}

.dt-sub {
  font-size: 16px;
  color: rgba(0, 0, 0, .5);
  margin-left: 16px;
}

.dt-subheader {
  background-color: rgb(246, 248, 248);
  border-bottom: 1px solid rgb(222, 229, 231);
  border-top: 1px solid rgb(222, 229, 231);
  padding: 10px 20px 10px 20px;
  height: 60px;
  line-height: 40px;
  font-size: 20px;
  font-weight: 300;
  color: rgb(88, 102, 110);
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dt-subheader.hitting {
  position: relative;
  margin-top: -1px;
  z-index: 1;
}

.dt-registration-card .dt-field {
  margin-top: 8px;
}

.dt-registration-card .dt-field:first-child {
  margin-top: 0px;
}

.dt-registration-card .dt-label {
  font-size: 13px;
  font-weight: 300;
  color: rgb(144, 147, 153);
  margin-bottom: 2px;
}

.dt-registration-card .dt-value {
  font-size: 14px;
}

</style>
